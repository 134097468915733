// This file is used in the minimal `embedEntry.ts`,
// so don't add any other imports here if at all possible.

// eslint-disable-next-line no-restricted-imports
import { LogsInitConfiguration, datadogLogs } from "@datadog/browser-logs";
import { RumInitConfiguration, datadogRum } from "@datadog/browser-rum";
// eslint-disable-next-line no-restricted-imports
import { getProductionEnvironment } from "@hex/common";

import { isCyDataValue } from "./cypress.js";
import { analyticsEnabled, datadogSite, hexVersion } from "./data";
import { startLoggingLongAnimationFrames } from "./longAnimationFrameLogger";
import { startLoggingMemoryUsage } from "./memoryLogger";
import { startLoggingSlowEvents } from "./slowEventLogger";

// All logs need to contain adequate info to debug to reduce noise and provide visbility into where users are hitting problems in the app.

let loggingInited = false;
export function initDatadogLogging(): void {
  if (loggingInited) return;
  loggingInited = true;

  const environment = getProductionEnvironment(window.location.hostname);

  if (analyticsEnabled) {
    if (datadogSite != null) {
      const ddSharedConfig = {
        clientToken:
          datadogSite === "datadoghq.eu"
            ? "pubc3952e08dcea093ebcaf6564d53fdcda"
            : "pubd41bd797abd4aeae0ffae0fa64091f74",
        site: datadogSite,
        service: "hex.client",
        env: environment,
        version: hexVersion,
      } satisfies Partial<LogsInitConfiguration & RumInitConfiguration>;

      const logsToIgnore = [
        "ResizeObserver loop limit exceeded", // See https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
        "ResizeObserver loop completed with undelivered notifications",
        "Internal server error", // GraphQL error - should be reported by server
        "Not authorized", // should be reported by the server
        "TypeError: n.load is not a function", // Occasional flake from Heap init
        "AbortError: The user aborted a request", // We don't care if requests are aborted, and intentionally do it sometimes
      ];
      datadogLogs.init({
        ...ddSharedConfig,
        // For now, only automatically forward error logs (tbd if this is too noisy or not noisy enough)
        forwardErrorsToLogs: true,
        forwardConsoleLogs: ["error"],
        // Opt-out of sending telemetry for datadog's staff use
        telemetrySampleRate: 0,
        beforeSend: (log) => {
          if (
            logsToIgnore.some((ignoreText) => log.message.includes(ignoreText))
          ) {
            return false;
          }

          // By default, DD stores the user id in a `usr.id` field.
          // This is fine, but to match our server logs and make it easier to query against,
          // we duplicate it here as well.
          log["userId"] =
            (log["usr"] as { id: string } | null)?.id ?? undefined;
          return true;
        },
      } satisfies LogsInitConfiguration);
      // logs messages to DD itself and to the console for debugging
      datadogLogs.logger.setHandler(["http", "console"]);

      datadogRum.init({
        ...ddSharedConfig,
        applicationId:
          datadogSite === "datadoghq.eu"
            ? "266a1aca-a1d2-45d9-b5ed-abea4f5d7ba1"
            : "d2847f2e-e35a-42d8-9821-8be97b586295",
        enableExperimentalFeatures: ["feature_flags"],
        // TODO(NOVA-1254): May be able to improve upon below in the interest of
        // minimizing future regressions / woes.
        allowedTracingUrls: [
          (origin) => {
            if (origin.startsWith("https://learn.hex.tech")) {
              return false;
            } else {
              return origin.match(/https:\/\/.*\.hex\.tech/) != null;
            }
          },
        ],
        actionNameAttribute: "data-cy",
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        telemetrySampleRate: 0,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: "mask",
        beforeSend: (event) => {
          // redact unknown action names to hide things like dropdown values
          // etc, only preserving action names specified by data-cy
          if (
            event.type === "action" &&
            // don't redact custom events since we're intentionally sending them
            event.action.type !== "custom" &&
            // redact all other actions though so we don't include user-generated text
            event.action.target != null &&
            !isCyDataValue(event.action.target.name)
          ) {
            event.action.target.name = "REDACTED";
          }
          return true;
        },
      } satisfies RumInitConfiguration);

      startLoggingMemoryUsage();
      startLoggingSlowEvents();
      startLoggingLongAnimationFrames();
    } else {
      console.error(
        "datadogSite not provided. datadogLogs and datadogRum are not active",
      );
    }
  }
}
